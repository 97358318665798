@use '../../styles/variables' as v;
@use '../../../node_modules/@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.mat-button-toggle-checked {
    border: 0.1rem solid mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
    color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
    background: mat.get-color-from-palette(v.$theme-primary-palette, 50) !important;
}

.mat-button-toggle:hover {
    background: mat.get-color-from-palette(v.$theme-primary-palette, 50) !important;
}

.mat-button-toggle-ripple {
    border-radius: 1.6rem;
}
