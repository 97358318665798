@use 'sass:color';

@mixin transparent-background-color-mixin($alpha, $color) {
    background-color: rgba(color.red($color), color.green($color), color.blue($color), $alpha);
}

@mixin transparent-background-mixin($alpha, $color) {
    background: rgba(color.red($color), color.green($color), color.blue($color), $alpha);
}

@mixin transparent-color-mixin($alpha, $color) {
    color: rgba(color.red($color), color.green($color), color.blue($color), $alpha);
}

@mixin respond-to($breakpoints...) {
    @each $breakpoint in $breakpoints {
        @if $breakpoint == 'tablet' {
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
                screen and (min-width: 1080px) and (max-width: 1080px) and (device-width: 1080px) and (orientation: landscape) and (min-resolution: 1dppx),
                screen and (min-width: 1024px) and (max-width: 1024px) and (device-width: 1024px) and (orientation: landscape) and (min-resolution: 1dppx),
                screen and (min-width: 1180px) and (max-width: 1180px) and (device-width: 1180px) and (orientation: landscape) and (min-resolution: 1dppx),
                screen and (min-width: 1194px) and (max-width: 1194px) and (device-width: 1194px) and (orientation: landscape) and (min-resolution: 1dppx),
                screen and (min-width: 1366px) and (max-width: 1366px) and (device-width: 1366px) and (orientation: landscape) and (min-resolution: 1dppx),
                screen and (min-width: 1368px) and (max-width: 1368px) and (device-width: 1368px) and (orientation: landscape) and (min-resolution: 1dppx),
                screen and (min-device-width: 602px) and (orientation: landscape) and (min-resolution: 1.331dppx) and (max-resolution: 1.332dppx),
                screen and (min-device-width: 602px) and (orientation: landscape) and (min-resolution: 2dppx) and (device-aspect-ratio: 40 / 23),
                screen and (min-width: 1280px) and (device-width: 1280px) and (max-width: 1280px) and (orientation: landscape) and (min-resolution: 1dppx) and (device-aspect-ratio: 8 / 5) {
                @content;
            }
        } @else if $breakpoint == 'desktop' {
            @media (min-width: 1024px) {
                @content;
            }
        }
    }
}
