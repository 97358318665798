@use '../../styles/variables' as v;
@use '../../../node_modules/@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
    background-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
}
